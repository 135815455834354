import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { css, Global } from "@emotion/react";
import emotionReset from "emotion-reset";
import { Footer, Header } from "../components";
import Theme from "../components/Theme";

declare global {
  interface Window {
    Intercom: any;
  }
}

const globalStyles = (smoothScrolling?: boolean) => css`
  ${emotionReset};

  body {
    overflow-x: hidden;
    background: ${Theme.palette.background};
  }

  html {
    box-sizing: border-box;
    ${smoothScrolling && `scroll-behavior: smooth`};
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  @font-face {
    font-family: "Gilroy-Regular";
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
    src: url("/assets/fonts/gilroy-regular.woff2") format("woff2"),
      url("/assets/fonts/gilroy-regular.woff") format("woff");
  }
  @font-face {
    font-family: "Gilroy-Bold";
    font-weight: 700;
    font-style: normal;
    font-display: fallback;
    src: url("/assets/fonts/gilroy-bold.woff2") format("woff2"), url("/assets/fonts/gilroy-bold.woff") format("woff");
  }
  @font-face {
    font-family: "Gilroy-Medium";
    font-weight: 500;
    font-style: normal;
    font-display: fallback;
    src: url("/assets/fonts/gilroy-medium.woff2") format("woff2"),
      url("/assets/fonts/gilroy-medium.woff") format("woff");
  }
`;

const styles = {
  page: (overflowHidden?: boolean) => css`
    ${overflowHidden && `overflow: hidden`};

    ${Theme.mq.xs} {
      padding-top: ${Theme.page.headerHeight[Theme.page.xs]}px;
    }
    ${Theme.mq.sm_} {
      padding-top: ${Theme.page.headerHeight[Theme.page.sm]}px;
    }

    // enable overflow protection on smaller screens since
    // we don't have sticky navigation in this case
    ${Theme.mq._sm} {
      overflow: hidden;
    }
  `,
};

export interface PageLayoutProps {
  children: React.ReactNode;
  title?: string;
  description?: string;
  keywords?: string;
  footerVisible?: boolean;
  footerCtaVisible?: boolean;
  smoothScrolling?: boolean;
  overflowHidden?: boolean;
}

export const PageLayout = ({
  title,
  description,
  keywords,
  footerVisible = true,
  footerCtaVisible = true,
  smoothScrolling,
  overflowHidden = true,
  children,
}: PageLayoutProps) => {
  useEffect(() => {
    if (window.Intercom) {
      window.Intercom("update", {
        hide_default_launcher: true,
      });
    }
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        {title && <title>{title}</title>}
        {keywords && <meta name="keywords" content={keywords} />}
        {description && <meta name="description" content={description} />}
      </Helmet>
      <Global styles={globalStyles(smoothScrolling)} />
      <div css={styles.page(overflowHidden)}>
        <Header ctaVisible />
        {children}
        {footerVisible && <Footer ctaVisible={footerCtaVisible} />}
      </div>
    </React.Fragment>
  );
};
