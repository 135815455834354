import { useEffect, useReducer } from "react";

const transitionTime = 1000;
const elastic = `transform ${transitionTime}ms cubic-bezier(0.68, -0.55, 0.265, 1.55)`;
const smooth = `transform ${transitionTime}ms ease`;

interface CarouselState {
  length: number;
  offset: number;
  desired: number;
  active: number;
  direction: number;
}

const init = (length: number): CarouselState => ({
  length: length,
  offset: 0,
  desired: 0,
  active: 0,
  direction: 0,
});

interface CarouselInitAction {
  type: "init";
  length: number;
}

interface CarouselNextAction {
  type: "next";
}

interface CarouselPrevAction {
  type: "prev";
}

interface CarouselJumpAction {
  type: "jump";
  desired: number;
}

interface CarouselDoneAction {
  type: "done";
}

type CarouselAction =
  | CarouselInitAction
  | CarouselJumpAction
  | CarouselNextAction
  | CarouselPrevAction
  | CarouselDoneAction;

function prev(length: number, current: number) {
  return (current - 1 + length) % length;
}

function next(length: number, current: number) {
  return (current + 1) % length;
}

function carouselReducer(state: CarouselState, action: CarouselAction): CarouselState {
  switch (action.type) {
    case "init":
      return init(action.length);
    case "jump":
      return {
        ...state,
        desired: action.desired,
        direction: 0,
      };
    case "next":
      return {
        ...state,
        desired: next(state.length, state.active),
        direction: -1,
      };
    case "prev":
      return {
        ...state,
        desired: prev(state.length, state.active),
        direction: 1,
      };
    case "done":
      return {
        ...state,
        offset: NaN,
        direction: 0,
        active: state.desired,
      };
    default:
      return state;
  }
}

export function useCarousel(
  length: number,
  interval: number
): [number, number, () => void, () => void, (n: number) => void, React.CSSProperties] {
  const [state, dispatch] = useReducer(carouselReducer, length, init);

  // re-initialize when length changes
  useEffect(() => {
    if (state.length !== length) {
      dispatch({ type: "init", length: length });
    }
  }, [length]);

  useEffect(() => {
    if (length > 1) {
      const id = setTimeout(() => dispatch({ type: "next" }), interval);
      return () => clearTimeout(id);
    }
  }, [state.offset, state.active, interval, length]);

  useEffect(() => {
    if (length > 1) {
      const id = setTimeout(() => dispatch({ type: "done" }), transitionTime);
      return () => clearTimeout(id);
    }
  }, [state.desired, length]);

  const shadowSlides = length > 1 ? 2 : 0;
  const style: React.CSSProperties = {
    transform: "translateX(0)",
    width: `${100 * (length + shadowSlides)}%`,
    left: `-${(state.active + (shadowSlides > 0 ? 1 : 0)) * 100}%`,
  };

  if (state.desired !== state.active) {
    const dist = Math.abs(state.active - state.desired);
    const pref = Math.sign(state.offset || 0);
    const dir = state.direction || (dist > length / 2 ? 1 : -1) * Math.sign(state.desired - state.active);
    const shift = (100 * (pref || dir)) / (length + shadowSlides);

    style.transition = smooth;
    style.transform = `translateX(${shift}%)`;
  } else if (!isNaN(state.offset)) {
    if (state.offset !== 0) {
      style.transform = `translateX(${state.offset}px)`;
    } else {
      style.transition = elastic;
    }
  }

  return [
    state.active,
    state.desired,
    () => dispatch({ type: "prev" }),
    () => dispatch({ type: "next" }),
    (n) => dispatch({ type: "jump", desired: n }),
    style,
  ];
}
