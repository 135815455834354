import { css } from "@emotion/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Layout from "../layouts";
import Theme, { Anchor } from "./Theme";

const styles = {
  base: css`
    [id]::before {
      ${Anchor};
    }
  `,
};

export interface MdxContentProps {
  body: string;
}

export const MdxContent = ({ body }: MdxContentProps) => (
  <article css={[Theme.typography.body.regular, styles.base]}>
    <Layout>
      <MDXRenderer>{body}</MDXRenderer>
    </Layout>
  </article>
);
