import { graphql, useStaticQuery } from "gatsby";
import { CallToActionData } from "./DataTypes";

export interface FooterData {
  cta: CallToActionData;
  links: Array<{
    text: string;
    href: string;
  }>;
}

export default function useFooterData(): FooterData {
  const data = useStaticQuery(
    graphql`
      query FooterDataQuery {
        mdx(fileAbsolutePath: { glob: "**/data/footer.md" }) {
          frontmatter {
            cta {
              heading
              content
              action {
                text
                href
              }
            }
            links {
              text
              href
            }
          }
        }
      }
    `
  );

  return data.mdx.frontmatter;
}
