import { useSiteData } from "../queries";
import { useIntercom } from "./useIntercom";

export function useSiteAction(href?: string) {
  const site = useSiteData();
  const onIntercom = useIntercom();

  if (href === "contact" || href === "intercom") {
    return {
      onClick: onIntercom,
    };
  } else {
    return {
      href: site.actions.find(({ key }) => key === href)?.href ?? href,
    };
  }
}
