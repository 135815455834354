import { css } from "@emotion/react";
import { getAssetImage } from "../core";
import { useFooterData, useSiteData } from "../queries";
import { ButtonSocial } from "./ButtonSocial";
import { CallToAction } from "./CallToAction";
import { Logo } from "./Logo";
import { NavLink } from "./NavLink";
import { Section } from "./Section";
import { StyleShape1, StyleShape5 } from "./Shapes";
import Theme from "./Theme";

const styles = {
  base: css`
    display: flex;
    flex-direction: column;
    position: relative;
  `,
  back1: css`
    ::after {
      ${StyleShape1};
      background-position: 50% calc(100% + 220px);
      bottom: 0;
    }
  `,
  back2: css`
    ::after {
      ${StyleShape5};
      background-position: 250px 950px;
      bottom: 0;
      right: 0;
    }
  `,
  cta: css`
    margin: 0 auto;

    ${Theme.mq._sm} {
      width: 80%;
    }

    ${Theme.mq.md} {
      width: 50%;
    }

    ${Theme.mq.lg_} {
      width: 40%;
    }
  `,
  main: css`
    display: grid;
    row-gap: 36px;
    padding: 64px 0;

    justify-items: center;
    align-items: center;

    ${Theme.mq.xs} {
      grid-template-areas:
        "links"
        "social"
        "legal";
    }

    ${Theme.mq.sm_} {
      row-gap: 12px;
      padding: 0;
      min-height: 240px;

      grid-template-areas:
        "links social"
        "legal legal";

      justify-content: space-between;
      align-items: end;
    }

    ${Theme.mq.md_} {
      grid-template-areas:
        "logo links social"
        "legal legal legal";
    }
  `,
  logo: css`
    ${Theme.mq._sm} {
      display: none;
    }
  `,
  links: css`
    display: grid;
    grid-auto-flow: column;
    grid-area: links;
    align-content: center;
    justify-content: center;
    justify-items: center;
    list-style-type: none;

    // special override for very small screens
    @media screen and (max-width: 370px) {
      grid-auto-flow: row;
    }

    ${Theme.mq._sm} {
      gap: 18px;
    }

    ${Theme.mq.sm_} {
      gap: 48px;
    }
  `,
  social: css`
    display: grid;
    grid-area: social;
    gap: 24px;
    grid-auto-flow: column;
    align-content: center;
    justify-content: center;
    list-style-type: none;
  `,
  legal: css`
    grid-area: legal;

    ${Theme.mq.sm_} {
      align-self: start;
      justify-self: start;
    }

    ${Theme.fonts.regular};
    ${Theme.fontSizes.legal};
    color: ${Theme.palette.muted};
  `,
};

export interface FooterProps {
  ctaVisible?: boolean;
}

export const Footer = ({ ctaVisible }: FooterProps) => {
  const footer = useFooterData();
  const site = useSiteData();

  return (
    <footer css={[styles.base, ctaVisible ? styles.back1 : styles.back2]}>
      <Section marginTop>
        {ctaVisible && footer.cta.action && footer.cta.action.text && (
          <CallToAction appearance="footer" alignment="center" {...footer.cta} sx={styles.cta} />
        )}
        <div css={styles.main}>
          <Logo sx={styles.logo} />
          <div css={styles.legal}>{site.copyright}</div>
          <ul css={styles.links}>
            {footer.links.map(({ text, href }, index) => (
              <li key={`i${index}`}>
                <NavLink to={href}>{text}</NavLink>
              </li>
            ))}
          </ul>
          <ul css={styles.social}>
            {site.social.map(({ image, text, href }, index) => (
              <ButtonSocial key={`i${index}`} text={text} image={getAssetImage(image)} href={href} />
            ))}
          </ul>
        </div>
      </Section>
    </footer>
  );
};
