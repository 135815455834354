import React, { useCallback, useState } from "react";
import { css } from "@emotion/react";
import { rgba } from "polished";
import IconClose from "../images/close.inline.svg";
import IconMenu from "../images/menu.inline.svg";
import { useMenuData } from "../queries";
import { Button } from "./Button";
import { ButtonIcon } from "./ButtonIcon";
import { Logo } from "./Logo";
import { Navigation } from "./Navigation";
import { NavLinkRaw } from "./NavLink";
import { Section } from "./Section";
import { StyleShape2 } from "./Shapes";
import Theme, { ButtonReset } from "./Theme";

const styles = {
  header: css`
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: ${Theme.zIndex.header};
    align-items: center;
    justify-content: center;
    background: ${Theme.palette.background};

    ${Theme.mq.xs} {
      height: ${Theme.page.headerHeight[Theme.page.xs]}px;
    }

    ${Theme.mq.sm_} {
      height: ${Theme.page.headerHeight[Theme.page.sm]}px;

      @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        backdrop-filter: blur(40px);
        background: linear-gradient(
          180deg,
          ${rgba(Theme.palette.background, 0.6)} 20%,
          ${rgba(Theme.palette.background, 0.3)} 100%
        );
      }
    }
  `,
  layout: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    // disable outline on the logo anchor
    > a[href="/"] {
      ${ButtonReset};
    }
  `,
  logo: css`
    flex: none;

    ${Theme.mq.xs} {
      height: 24px;
      width: 127px;
    }
  `,
  menu: css`
    flex: 1 1 auto;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr max-content;

    ${Theme.mq.xs} {
      display: none;
    }
  `,
  expansion: css`
    position: absolute;
    top: ${Theme.page.headerHeight[Theme.page.xs]}px;
    left: 0;
    right: 0;
    padding: ${Theme.page.padding[Theme.page.xs]}px;

    background-color: ${Theme.palette.background};
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      backdrop-filter: blur(40px);
      background: linear-gradient(
        180deg,
        ${rgba(Theme.palette.background, 0.9)} 20%,
        ${rgba(Theme.palette.background, 0.8)} 100%
      );
    }

    ::before {
      ${StyleShape2};
      z-index: 0;
      top: -50%;
      left: calc(min(-80%, -450px));
      transform: scale(0.6);
      opacity: 0.3;
    }

    ul + a,
    ul + button {
      width: 50%;
      margin: 30px auto 0 auto;
    }

    ${Theme.mq.sm_} {
      display: none;
    }
  `,
  button: css`
    ${Theme.mq.sm_} {
      display: none;
    }
  `,
};

export interface HeaderProps {
  ctaVisible?: boolean;
}

const Menu = ({ ctaVisible, mobile }: HeaderProps & { mobile?: boolean }) => {
  const menu = useMenuData();
  return (
    <React.Fragment>
      <Navigation menu={menu.links} mobile={mobile} />
      {ctaVisible && (
        <Button href={menu.action.href} height={48}>
          {menu.action.text}
        </Button>
      )}
    </React.Fragment>
  );
};

export const Header = (props: HeaderProps) => {
  const [visible, setVisible] = useState(false);
  const toggle = useCallback(() => setVisible((prev: boolean) => !prev), [setVisible]);

  return (
    <nav css={styles.header}>
      <Section sx={styles.layout}>
        <NavLinkRaw to="/">
          <Logo sx={styles.logo} />
        </NavLinkRaw>
        <div css={styles.menu}>
          <Menu {...props} />
        </div>
        <ButtonIcon width={24} height={24} onClick={toggle} opacity={0.8} color={Theme.palette.text} sx={styles.button}>
          {visible ? <IconClose /> : <IconMenu />}
        </ButtonIcon>
        {visible && (
          <div css={[styles.expansion]}>
            <Menu {...props} mobile />
          </div>
        )}
      </Section>
    </nav>
  );
};
