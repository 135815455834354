import { css } from "@emotion/react";
import Shape1 from "../images/shape1.svg";
import Shape2 from "../images/shape2.svg";
import Shape3 from "../images/shape3.svg";
import Shape4 from "../images/shape4.svg";
import Shape5 from "../images/shape5.svg";
import Shape6 from "../images/shape6.svg";
import Theme from "./Theme";

const StyleBackground = css`
  content: "";
  position: absolute;
  background-repeat: no-repeat;
  pointer-events: none;
  z-index: ${Theme.zIndex.background};
`;

export const StyleShape1 = css`
  height: 1220px;
  width: 100%;
  background-image: url(${Shape1});
  ${StyleBackground};
`;

export const StyleShape2 = css`
  width: 1225px;
  height: 881px;
  background-image: url(${Shape2});
  background-position: 0 50%;
  ${StyleBackground};
`;

export const StyleShape3 = css`
  width: 1155px;
  height: 1112px;
  background-image: url(${Shape3});
  background-position: 0 50%;
  ${StyleBackground};
`;

export const StyleShape4 = css`
  width: 1719px;
  height: 1421px;
  background-image: url(${Shape4});
  background-position: 50% 0;
  ${StyleBackground};
`;

export const StyleShape5 = css`
  width: 1694px;
  height: 1261px;
  background-image: url(${Shape5});
  ${StyleBackground};
`;

export const StyleShape6 = css`
  width: 1087px;
  height: 1037px;
  background-image: url(${Shape6});
  background-position: 260px 50%;
  ${StyleBackground};
`;
