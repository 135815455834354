import { css } from "@emotion/react";
import { rgba } from "polished";
import { hasChildContent } from "../core";
import { NavLinkRaw } from "./NavLink";
import Theme, { BackdropFilter, ButtonReset } from "./Theme";

const active = css`
  > h6 {
    ${Theme.fonts.bold};
    color: ${Theme.palette.text};
  }
  > p {
    color: ${Theme.palette.text};
  }
`;

const styles = {
  default: css`
    ${ButtonReset};
    text-align: left; // reset
    text-decoration: none;

    display: grid;
    grid-template-columns: minmax(0, auto) 1fr;
    grid-template-rows: 1fr minmax(0, auto);
    grid-template-areas:
      "image header"
      "image content";

    align-items: center;

    background: none;
    border: none;

    min-width: min-content;
    position: relative;
    cursor: pointer;

    border-radius: 8px;
    padding: 24px;

    > h6 {
      grid-area: header;
      ${Theme.fonts.medium};
      ${Theme.fontSizes.h6};
      color: ${Theme.palette.light};
    }

    > p {
      grid-area: content;
      margin-top: 6px;
      color: ${Theme.palette.muted};
    }

    &:hover,
    &:focus {
      ${active};
      text-decoration: none;
    }

    overflow: hidden;

    > img {
      grid-area: image;
      justify-self: center;
      margin: 0 24px 0 0;
      height: 48px;
      width: 48px;
    }
  `,
  checked: css`
    ${active};
    ${BackdropFilter};

    background: radial-gradient(
      circle at 70% 200%,
      ${rgba(Theme.palette.glow, 0.5)} 0%,
      ${rgba(Theme.palette.gray, 0.25)} 100%
    );
  `,
};

export interface ButtonPaneProps {
  header: React.ReactNode;
  children?: React.ReactNode;
  image?: string;
  checked?: boolean;
  href?: string;
  onClick?: () => void;
}

export const ButtonPane = ({ header, image, children, checked, href, onClick }: ButtonPaneProps) =>
  href ? (
    <NavLinkRaw
      to={href}
      css={[styles.default, checked && styles.checked]}
      role="radio"
      tabIndex={0}
      aria-checked={checked}>
      {image && <img src={image} />}
      <h6>{header}</h6>
      {hasChildContent(children) && <p css={Theme.typography.body.regular}>{children}</p>}
    </NavLinkRaw>
  ) : (
    <button
      css={[styles.default, checked && styles.checked]}
      role="radio"
      tabIndex={0}
      aria-checked={checked}
      onClick={onClick}>
      {image && <img src={image} />}
      <h6>{header}</h6>
      {hasChildContent(children) && <p css={Theme.typography.body.regular}>{children}</p>}
    </button>
  );
