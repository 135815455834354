import { useEffect, useReducer, useRef } from "react";
import useInterval from "./useInterval";

interface RotatorState {
  length: number;
  active: number;
  desired: number;
  phase: "none" | "in" | "out";
  items: string[];
  text: string;
}

const init = (items: string[]): RotatorState => ({
  length: items.length,
  active: 0,
  desired: 0,
  phase: "none",
  items: items,
  text: items[0],
});

interface RotatorInitAction {
  type: "init";
  items: string[];
}

interface RotatorNextAction {
  type: "next";
}

interface RotatorBackAction {
  type: "back";
  animate: boolean;
}

interface RotatorTypeAction {
  type: "type";
}

interface RotatorDoneAction {
  type: "done";
}

type RotatorAction = RotatorInitAction | RotatorNextAction | RotatorBackAction | RotatorTypeAction | RotatorDoneAction;

function rotatorReducer(state: RotatorState, action: RotatorAction): RotatorState {
  switch (action.type) {
    case "init":
      return init(action.items);
    case "next":
      return {
        ...state,
        desired: (state.active + 1) % state.length,
        phase: "out",
      };
    case "back":
      return {
        ...state,
        text: action.animate ? state.text.slice(0, state.text.length - 1) : "",
        phase: action.animate ? (state.text.length > 1 ? "out" : "in") : "in",
      };
    case "type":
      return {
        ...state,
        text: state.items[state.desired].slice(0, state.text.length + 1),
        phase: state.text.length + 1 < state.items[state.desired].length ? "in" : "none",
      };
    case "done":
      return {
        ...state,
        active: state.desired,
        text: state.items[state.desired],
      };
  }
}

const TICK = 30;

export function useTextRotator(items: string[], interval: number): [string, number, number] {
  const [state, dispatch] = useReducer(rotatorReducer, items, init);
  const sleepTime = useRef(0);

  useEffect(() => {
    if (state.items !== items) {
      dispatch({ type: "init", items: items });
    }
  }, [items]);

  useInterval(() => {
    if (state.active !== state.desired) {
      switch (state.phase) {
        case "out":
          dispatch({ type: "back", animate: false });
          break;
        case "in":
          dispatch({ type: "type" });
          break;
        case "none":
          dispatch({ type: "done" });
          break;
      }
    } else {
      // sleep and rotate
      sleepTime.current = sleepTime.current + TICK;
      if (sleepTime.current >= interval) {
        sleepTime.current = 0;
        dispatch({ type: "next" });
      }
    }
  }, TICK);

  return [state.text, state.active, state.desired];
}
