import styled from "@emotion/styled";
import { NavLinkRaw } from "./NavLink";
import Theme, { ButtonReset } from "./Theme";

export const AccentLink = styled(NavLinkRaw)`
  ${ButtonReset};

  ${Theme.fonts.medium};
  ${Theme.fontSizes.navlink};
  color: ${Theme.palette.primary};

  display: flex;
  align-items: center;
  margin: 0;
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;
  flex-wrap: nowrap;
  opacity: ${Theme.opacity.muted};

  height: 24px;

  > svg {
    display: block;
    width: 18px;
    height: 18px;
    fill: ${Theme.palette.primary};
  }

  &:hover,
  &:focus {
    ${Theme.fonts.medium};
    opacity: 1;
    color: ${Theme.palette.primary};
  }
`;
