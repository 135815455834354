import { css } from "@emotion/react";
import { NavLink } from "./NavLink";
import Theme from "./Theme";

const styles = (count: number) => css`
  align-content: center;
  justify-content: center;
  list-style-type none;

  ${Theme.mq.xs} {
    display: grid;
    grid-auto-flow: row;
    grid-row-gap: 30px;
    justify-items: center;
  }

  ${Theme.mq.sm_} {
    display: grid;
    grid-template-columns: minmax(12px, 48px) repeat(${count}, min-content minmax(12px, 48px));
  }
`;

export interface NavigationProps {
  mobile?: boolean;
  menu: Array<{
    text: string;
    href: string;
  }>;
}

export const Navigation = ({ mobile, menu }: NavigationProps) => (
  <ul css={styles(menu.length)} role="menubar">
    {menu.map(({ text, href }, index) => (
      <li key={`i${index}`} role="none" style={mobile ? undefined : { gridColumn: index * 2 + 2 }}>
        <NavLink role="menuitem" to={href}>
          {text}
        </NavLink>
      </li>
    ))}
  </ul>
);
