import { graphql, useStaticQuery } from "gatsby";
import { TestimonialType } from "./DataTypes";

export interface QuotesData {
  quotes: Array<{
    id: string;
    type: TestimonialType;
    name: string;
    moniker?: string;
    content: string;
  }>;
}

export default function useQuotesData(): QuotesData {
  const data = useStaticQuery(
    graphql`
      query QuotesDataQuery {
        mdx(fileAbsolutePath: { glob: "**/data/quotes.md" }) {
          frontmatter {
            quotes {
              id
              type
              name
              moniker
              content
            }
          }
        }
      }
    `
  );

  return data.mdx.frontmatter;
}
