import { css } from "@emotion/react";
import { useSiteAction } from "../core";
import { NavLinkRaw } from "./NavLink";
import Theme, { ButtonReset } from "./Theme";
import { StyleableProps } from "./Types";

const styles = {
  base: (height: number) => css`
    ${ButtonReset};

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    text-decoration: none;
    cursor: pointer;

    ${Theme.typography.control};

    height: ${height}px;
    padding: 0 ${height > 42 ? "32px" : "24px"};
    border-radius: 8px;

    white-space: nowrap;
    flex-wrap: nowrap;

    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      backdrop-filter: blur(40px);
    }

    &:hover,
    &:focus {
      text-decoration: none;
    }
  `,
  primary: css`
    background: ${Theme.gradients.primary};
    &:hover,
    &:focus {
      background: ${Theme.palette.secondary};
    }
  `,
  secondary: css`
    border: solid thin ${Theme.palette.primary};
    &:hover,
    &:focus {
      border-color: ${Theme.palette.secondary};
    }
  `,
};

const compose = (appearance: ButtonAppearance, height: number) => [
  styles.base(height),
  appearance === "primary" && styles.primary,
  appearance === "secondary" && styles.secondary,
];

export type ButtonAppearance = "primary" | "secondary";
export interface ButtonProps extends StyleableProps {
  children: React.ReactNode;
  appearance?: ButtonAppearance;
  href?: string;
  height?: number;
  onClick?: () => void;
}

const ButtonEx = ({ children, appearance = "primary", href, height = 54, onClick, sx }: ButtonProps) =>
  href ? (
    <NavLinkRaw to={href} css={[...compose(appearance, height), sx]}>
      {children}
    </NavLinkRaw>
  ) : (
    <button css={[...compose(appearance, height), sx]} onClick={onClick}>
      {children}
    </button>
  );

export const Button = ({ href, ...other }: ButtonProps) => {
  const props = useSiteAction(href);
  return <ButtonEx {...other} {...props} />;
};
