import { useMemo } from "react";
import { css } from "@emotion/react";
import { useCarousel } from "../core";
import { useQuotesData } from "../queries";
import { TestimonialType } from "../queries/DataTypes";
import { Card } from "./Card";
import Theme, { ButtonReset } from "./Theme";

const styles = {
  layout: css`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  carousel: css`
    flex: 1 1 auto;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 256px;

    > div {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      height: 100%;
      position: absolute;
    }
  `,
  quote: css`
    width: 100%;
    flex: 0 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `,
  content: (alignment: "left" | "center") => css`
    ${Theme.fontSizes.h6};
    ${Theme.fonts.regular};
    color: ${Theme.palette.text};
    text-align: ${alignment};
  `,
  author: (alignment: "left" | "center") => css`
    display: flex;
    flex-direction: column;
    align-items: ${alignment === "left" ? "flex-start" : "center"};
  `,
  name: css`
    ${Theme.fonts.regular};
    ${Theme.fontSizes.p};
    color: ${Theme.palette.text};
  `,
  moniker: css`
    ${Theme.fonts.regular};
    ${Theme.fontSizes.small};
    color: ${Theme.palette.muted};
  `,
  anchors: css`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 4px;
    margin-top: 48px;

    > button {
      ${ButtonReset};
      background-color: ${Theme.palette.primary};
      width: 32px;
      height: 3px;
      cursor: pointer;
      transition-duration: 1000ms;
      transition-property: width;
      transition-timing-function: ease-in-out;

      &[data-active="true"] {
        width: 80px;
      }
    }
  `,
  card: css`
    overflow: hidden;
    padding: 32px;
    width: 100%;
  `,
};

interface QuoteProps {
  name: string;
  moniker?: string;
  content: string;
  alignment: "left" | "center";
}

const Quote = ({ name, moniker, content, alignment }: QuoteProps) => (
  <div css={styles.quote}>
    <div css={styles.content(alignment)} dangerouslySetInnerHTML={{ __html: content }} />
    <div css={styles.author(alignment)}>
      <span css={styles.name}>{name}</span>
      {moniker && <span css={styles.moniker}>{moniker}</span>}
    </div>
  </div>
);

export interface QuotesProps {
  keys: Array<string>;
  card?: boolean;
}

function order(type: TestimonialType) {
  switch (type) {
    case "appteams":
      return 1;
    case "infrateams":
      return 2;
    case "advisors":
      return 3;
  }
}

export const Quotes = ({ keys, card }: QuotesProps) => {
  const quotesData = useQuotesData();
  const items = useMemo(() => {
    var set = new Set(keys);
    const items = quotesData.quotes.filter(({ id }) => set.has(id));
    items.sort((a, b) => order(a.type) - order(b.type));
    return items;
  }, [quotesData, keys]);

  const [active, desired, , , jump, style] = useCarousel(items.length, 10000);
  const alignment = card ? "left" : "center";
  const carousel = (
    <div css={styles.carousel}>
      <div style={style}>
        {items.length > 1 && <Quote {...items[items.length - 1]} alignment={alignment} />}
        {items.map((item, index) => (
          <Quote key={`i${index}`} {...item} alignment={alignment} />
        ))}
        {items.length > 1 && <Quote {...items[0]} alignment={alignment} />}
      </div>
    </div>
  );

  return (
    <div css={styles.layout}>
      {card ? <Card sx={styles.card}>{carousel}</Card> : carousel}
      {items.length > 1 && (
        <div css={styles.anchors}>
          {items.map((_, index) => (
            <button key={`i${index}`} data-active={index === desired} onClick={() => jump(index)} />
          ))}
        </div>
      )}
    </div>
  );
};
