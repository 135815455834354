import { graphql, useStaticQuery } from "gatsby";
import { CallToActionData } from "./DataTypes";

export interface SiteData {
  copyright: string;
  actions: Array<{
    key: string;
    href: string;
  }>;
  social: Array<{
    image: string;
    text: string;
    href: string;
  }>;
  notfound: CallToActionData;
}

export default function useSiteData(): SiteData {
  const data = useStaticQuery(
    graphql`
      query SiteDataQuery {
        mdx(fileAbsolutePath: { glob: "**/data/site.md" }) {
          frontmatter {
            copyright
            actions {
              key
              href
            }
            social {
              image
              text
              href
            }
            notfound {
              heading
              content
              action {
                text
                href
              }
            }
          }
        }
      }
    `
  );

  return data.mdx.frontmatter;
}
