import { css } from "@emotion/react";
import LogoDark from "../images/logo-d.svg";
import LogoLight from "../images/logo-l.svg";
import { StyleableProps } from "./Types";

const styles = css`
  display: block;
  width: 158px;
  height: 30px;
  background-image: url(${LogoDark});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
`;

export interface LogoProps extends StyleableProps {}

export const Logo = ({ sx }: LogoProps) => <div css={[styles, sx]} />;
