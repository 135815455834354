import { css } from "@emotion/react";
import { CallToActionData } from "../queries/DataTypes";
import { CallToAction } from "./CallToAction";
import { MdxContent } from "./MdxContent";

const styles = {
  content: css`
    max-width: 960px;
    margin: 0 auto;

    > article {
      margin-top: 48px;
    }
  `,
};

export interface BlockMdxContentProps {
  hero: CallToActionData;
  body: string;
}

export const BlockMdxContent = ({ hero, body }: BlockMdxContentProps) => (
  <div css={styles.content}>
    <CallToAction appearance="secondary" {...hero} />
    <MdxContent body={body} />
  </div>
);
