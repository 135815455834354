import { css } from "@emotion/react";
import { CallToActionData } from "../queries/DataTypes";
import { Button } from "./Button";
import Theme from "./Theme";
import { StyleableProps } from "./Types";

const styles = {
  base: css`
    display: flex;
    flex-direction: column;

    > h1 + div,
    > h2 + div,
    > h3 + div,
    > h4 + div {
      margin-top: 24px;
    }
    > h5 + div,
    > h6 + div {
      margin-top: 16px;
    }

    > h1 ~ a,
    > h2 ~ a,
    > h3 ~ a,
    > h4 ~ a,
    > h1 ~ button,
    > h2 ~ button,
    > h3 ~ button,
    > h4 ~ button {
      margin-top: 36px;
    }

    > h5 ~ a,
    > h6 ~ a,
    > h5 ~ button,
    > h6 ~ button {
      margin-top: 24px;
    }
  `,
  prehead: css`
    ${Theme.fonts.medium};
    ${Theme.fontSizes.p};
    color: ${Theme.palette.muted};
    text-transform: uppercase;
    letter-spacing: 0.06em;
    margin-bottom: 6px;
  `,
  center: css`
    align-items: center;
    text-align: center;
    margin: 0 auto;
  `,
  left: css`
    align-items: flex-start;
    text-align: left;
  `,
  responsive: (width: string) => css`
    ${Theme.mq.md_} {
      width: ${width};
    }
  `,
};

export interface CallToActionProps extends CallToActionData, StyleableProps {
  alignment?: "left" | "center";
  appearance: "primary" | "secondary" | "tertiary" | "footer" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  actionAppearance?: "primary" | "secondary";
  actionHeight?: number;
  actionIcon?: React.ReactNode;
  width?: string;
}

export const CallToAction = ({
  prehead,
  heading,
  content,
  action,
  alignment = "left",
  appearance,
  actionAppearance = "primary",
  actionHeight,
  actionIcon,
  width = "100%",
  sx,
}: CallToActionProps) => (
  <div
    css={[
      styles.base,
      styles.responsive(width),
      alignment === "left" && styles.left,
      alignment === "center" && styles.center,
      sx,
    ]}>
    {prehead && <div css={styles.prehead}>{prehead}</div>}
    {(appearance === "primary" || appearance === "h1") && (
      <h1 css={Theme.typography.h1} dangerouslySetInnerHTML={{ __html: heading }} />
    )}
    {(appearance === "secondary" || appearance === "h2") && (
      <h2 css={Theme.typography.h2} dangerouslySetInnerHTML={{ __html: heading }} />
    )}
    {(appearance === "tertiary" || appearance === "h3") && (
      <h3 css={Theme.typography.h3} dangerouslySetInnerHTML={{ __html: heading }} />
    )}
    {(appearance === "footer" || appearance === "h4") && (
      <h4 css={Theme.typography.h4} dangerouslySetInnerHTML={{ __html: heading }} />
    )}
    {appearance === "h5" && <h5 css={Theme.typography.h5} dangerouslySetInnerHTML={{ __html: heading }} />}
    {appearance === "h6" && <h6 css={Theme.typography.h6} dangerouslySetInnerHTML={{ __html: heading }} />}
    {content && <div css={Theme.typography.p} dangerouslySetInnerHTML={{ __html: content }} />}
    {action && action.text && (
      <Button appearance={actionAppearance} height={actionHeight} href={action.href}>
        {action.text}
        {actionIcon}
      </Button>
    )}
  </div>
);
