import { css } from "@emotion/react";
import { getAssetImage } from "../core";
import More from "../images/more.inline.svg";
import { CallToActionData } from "../queries/DataTypes";
import { CallToAction } from "./CallToAction";
import { Section } from "./Section";
import Theme from "./Theme";

const ScrollBarAdjustment = 16;
const minWidth = (size: number, columns: number, gap: number) =>
  Math.ceil(
    (Theme.page.breakpoints[size] - ScrollBarAdjustment - 2 * Theme.page.padding[size + 1] - (columns - 1) * gap) /
      columns
  );

const styles = {
  layout: (columns: number) => css`
    margin-top: 36px;
    display: grid;

    grid-auto-flow: row;
    grid-gap: 48px;

    ${Theme.mq.sm_} {
      grid-template-columns: repeat(
        ${Math.min(columns, 2 + (columns % 2))},
        minmax(${minWidth(Theme.page.xs, Math.min(columns, 2 + (columns % 2)), 48)}px, 1fr)
      );
      justify-content: space-between;
      grid-gap: 48px;
      margin-top: 48px;
    }

    ${Theme.mq.md_} {
      grid-gap: 60px;
    }

    ${Theme.mq.lg_} {
      grid-template-columns: repeat(auto-fit, minmax(${minWidth(Theme.page.md, Math.min(columns, 4), 60)}px, 1fr));
      grid-gap: 60px;
    }
  `,
  entry: css`
    display: flex;
    flex-direction: column;

    > img {
      height: 48px;
      width: 48px;
      margin-bottom: 24px;

      ${Theme.mq.xs} {
        display: none;
      }
    }
  `,
  icon: css`
    > svg {
      display: block;
      stroke: ${Theme.palette.text};
    }
    width: 12px;
    height: 12px;
    margin: 0 -6px 0 6px;
  `,
};

export interface BlockMoreProps {
  alignment?: "left" | "center";
  heading?: string;
  content?: string;
  items: Array<
    CallToActionData & {
      image: string;
    }
  >;
}

export const BlockMore = ({ alignment, heading, content, items }: BlockMoreProps) => (
  <Section marginTop marginBottom>
    {heading && (
      <CallToAction width="50%" appearance="secondary" heading={heading} content={content} alignment={alignment} />
    )}
    <div css={styles.layout(items.length)}>
      {items.map(({ image, ...other }, index) => (
        <div key={`i${index}`} css={styles.entry}>
          <img src={getAssetImage(image)} alt={other.heading} />
          <CallToAction
            appearance="h6"
            actionAppearance="secondary"
            actionHeight={36}
            actionIcon={
              <div css={styles.icon}>
                <More />
              </div>
            }
            {...other}
          />
        </div>
      ))}
    </div>
  </Section>
);
