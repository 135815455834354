import { css } from "@emotion/react";
import Theme, { ButtonReset } from "./Theme";

const styles = css`
  ${ButtonReset};

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  width: 32px;
  height: 32px;
  padding: 0;
  background: ${Theme.palette.background};
  color: ${Theme.palette.text};
  border: none;
  border-radius: 100%;

  > img {
    height: 10px;
  }

  // gradient border (requires non-transparent background)
  ::after {
    content: "";
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    background: ${Theme.gradients.border};
    z-index: -1;
    border-radius: 100%;
  }

  &:hover,
  &:focus {
    ::after {
      background: ${Theme.gradients.primary};
    }

    background: ${Theme.gradients.primary};

    // shadow-halo-effect on hover
    ::before {
      content: "";
      position: absolute;
      width: 24px;
      height: 24px;
      left: 4px;
      top: 14px;
      background: ${Theme.gradients.primary};
      opacity: 0.8;
      filter: blur(24px);
    }
  }
`;

export interface ButtonSocialProps {
  image: string;
  text: string;
  href: string;
}

export const ButtonSocial = ({ image, text, href }: ButtonSocialProps) => (
  <a href={href} target="_blank" css={styles}>
    <img src={image} alt={text} />
  </a>
);
