import styled from "@emotion/styled";
import { GatsbyLinkProps, Link } from "gatsby";
import Bullet from "../images/bullet.svg";
import Theme, { ButtonReset } from "./Theme";

export const NavLinkRaw = <T extends unknown>(props: Omit<GatsbyLinkProps<T>, "ref">) =>
  props.to.indexOf("http") >= 0 ? (
    <a target="_blank" href={props.to} className={props.className}>
      {props.children}
    </a>
  ) : (
    <Link<T> {...props} activeClassName="activeLink" />
  );

export const NavLink = styled(NavLinkRaw)`
  ${ButtonReset};

  ${Theme.fonts.medium};
  ${Theme.fontSizes.navlink};
  color: ${Theme.palette.text};

  display: flex;
  margin: 0;
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;
  flex-wrap: nowrap;
  opacity: ${Theme.opacity.muted};

  &:hover,
  &:focus {
    ${Theme.fonts.medium};
    opacity: 1;
    color: ${Theme.palette.text};
  }

  &.activeLink {
    opacity: 1;
    position: relative;
    ::after {
      content: "";
      background-image: url(${Bullet});
      background-size: 4px 4px;
      background-position: 50%;
      background-repeat: no-repeat;
      width: 4px;
      height: 4px;
      position: absolute;
      bottom: -4px;
      left: calc(50% - 4px);
    }
  }
`;
