import { graphql, useStaticQuery } from "gatsby";

export interface MenuData {
  action: {
    text: string;
    href: string;
  };
  links: Array<{
    text: string;
    href: string;
  }>;
}

export default function useMenuData(): MenuData {
  const data = useStaticQuery(
    graphql`
      query MenuDataQuery {
        mdx(fileAbsolutePath: { glob: "**/data/menu.md" }) {
          frontmatter {
            action {
              text
              href
            }
            links {
              text
              href
            }
          }
        }
      }
    `
  );

  return data.mdx.frontmatter;
}
