import { css } from "@emotion/react";
import { rgba } from "polished";
import Theme, { BackdropFilter } from "./Theme";
import { StyleableProps } from "./Types";

const styles = css`
  display: flex;
  position: relative;
  border-radius: 16px;
  border: solid 2px ${rgba(Theme.palette.border, 0.7)};

  ${BackdropFilter};
`;

export interface CardProps extends StyleableProps {
  children: React.ReactNode;
}

export const Card = ({ children, sx }: CardProps) => <div css={[styles, sx]}>{children}</div>;
