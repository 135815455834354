import { css } from "@emotion/react";
import Theme from "./Theme";
import { StyleableProps } from "./Types";

const padding = (size: number, marginTop?: boolean, marginBottom?: boolean) => css`
  padding-left: ${Theme.page.padding[size]}px;
  padding-right: ${Theme.page.padding[size]}px;
  padding-top: ${marginTop ? Theme.section.padding[size] : "0"}px;
  padding-bottom: ${marginBottom ? Theme.section.padding[size] : "0"}px;
`;

const styles = (marginTop?: boolean, marginBottom?: boolean) => css`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;

  ${Theme.mq.xl} {
    ${padding(Theme.page.xl, marginTop, marginBottom)};
  }

  ${Theme.mq.lg} {
    ${padding(Theme.page.lg, marginTop, marginBottom)};
  }

  ${Theme.mq.md} {
    ${padding(Theme.page.md, marginTop, marginBottom)};
  }

  ${Theme.mq.sm} {
    ${padding(Theme.page.sm, marginTop, marginBottom)};
  }

  ${Theme.mq.xs} {
    ${padding(Theme.page.xs, marginTop, marginBottom)};
  }
`;

export interface SectionProps extends StyleableProps {
  children: React.ReactNode;
  marginTop?: boolean;
  marginBottom?: boolean;
}

export const Section = ({ children, sx, marginTop, marginBottom }: SectionProps) => (
  <div css={[styles(marginTop, marginBottom), sx]}>{children}</div>
);
