import React, { useEffect } from "react";
import lottie, { AnimationItem } from "lottie-web";

export function useLottieAnimation(container: React.RefObject<Element>, data: any) {
  useEffect(() => {
    let animation: AnimationItem | undefined;
    if (container.current) {
      animation = lottie.loadAnimation({
        container: container.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: data,
      });
    }
    return () => animation?.destroy();
  }, []);
}
