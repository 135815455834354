import { useMemo } from "react";
import { css } from "@emotion/react";
import { useActiveId } from "../core";
import Bullet from "../images/bullet.svg";
import Theme, { ButtonReset } from "./Theme";

const styles = {
  base: css`
    a {
      ${ButtonReset};

      color: ${Theme.palette.text};
      text-decoration: none;

      display: flex;
      margin: 0;
      cursor: pointer;
      text-decoration: none;
      opacity: ${Theme.opacity.muted};

      &:hover,
      &:focus {
        opacity: 1;
      }
    }

    > ol > li {
      & + li {
        margin-top: 30px;
      }

      > ol {
        margin-top: 12px;
      }
    }

    > ol > li > ol > li {
      & + li {
        margin-top: 12px;
      }
    }

    > ol > li > a {
      ${Theme.fonts.bold};
      ${Theme.fontSizes.h6};
      opacity: 1;
    }

    > ol > li > ol > li > a {
      ${Theme.fonts.regular};
      ${Theme.fontSizes.link};
    }
  `,
  active: css`
    color: ${Theme.palette.text} !important;
    opacity: 1 !important;
    position: relative;

    ::before {
      content: "";
      background-image: url(${Bullet});
      background-size: 4px 4px;
      background-repeat: no-repeat;
      height: 4px;
      width: 4px;
      position: absolute;
      left: -10px;
      top: calc(50% - 2px);
    }
  `,
};

export interface TableOfContentsItem {
  url: string;
  title: string;
  items: Array<TableOfContentsItem>;
}

export interface ToCProps {
  items: Array<TableOfContentsItem>;
}

const renderItems = (items: Array<TableOfContentsItem>, activeId?: string) => (
  <ol>
    {items.map((item) => (
      <li key={item.url}>
        <a href={item.url} css={[activeId === item.url.slice(1) && styles.active]}>
          {item.title}
        </a>
        {item.items && renderItems(item.items, activeId)}
      </li>
    ))}
  </ol>
);

const getIds = (items: Array<TableOfContentsItem>) =>
  items.reduce((acc, item) => {
    if (item.url) {
      acc.push(item.url.slice(1)); // remove #
    }
    if (item.items) {
      acc.push(...getIds(item.items));
    }
    return acc;
  }, [] as string[]);

export const ToC = ({ items }: ToCProps) => {
  const ids = useMemo(() => getIds(items), [items]);
  const activeId = useActiveId(ids);

  return <nav css={styles.base}>{renderItems(items, activeId)}</nav>;
};
