import { css } from "@emotion/react";
import { NavLinkRaw } from "./NavLink";
import Theme, { ButtonReset } from "./Theme";
import { StyleableProps } from "./Types";

const styles = (width: number, height: number, opacity: number, color?: string) => css`
  ${ButtonReset};

  display: flex;
  align-items: center;
  justify-content: center;

  background: none;
  border: none;
  text-decoration: none;
  margin: 0;
  padding: 0;

  width: ${width}px;
  height: ${height}px;
  cursor: pointer;

  > svg {
    display: block;
    width: 100%;
    height: 100%;
    fill: ${color ?? Theme.palette.primary};
    opacity: ${opacity};
  }

  &:hover,
  &:focus {
    > svg {
      opacity: 1;
    }
    text-decoration: none;
  }
`;

export interface ButtonIconProps extends StyleableProps {
  children: React.ReactNode;
  href?: string;
  width: number;
  height: number;
  opacity?: number;
  color?: string;
  onClick?: () => void;
}

export const ButtonIcon = ({ children, href, width, height, onClick, opacity = 0.2, color, sx }: ButtonIconProps) =>
  href ? (
    <NavLinkRaw to={href} css={[styles(width, height, opacity, color), sx]} onClick={onClick}>
      {children}
    </NavLinkRaw>
  ) : (
    <button css={[styles(width, height, opacity, color), sx]} onClick={onClick}>
      {children}
    </button>
  );
